import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import AddressSearchBox from '../../common/address-search-box/address-search-box';
import { Typography, Link, Grid, Box } from '@material-ui/core';
import moment from 'moment-timezone';
import * as Enums from '../../../enums';
import { EarlyVotingIcon, ElectionDayVotingIcon, PostalVotingIcon, NavForwardArrowIcon, ElectionOfficeIcon } from '../../common/svg-icons';
import BrowseFooter from './browseFooter';
import {Tools} from '../../../tools';

const styles = theme => {
    return ({
        searchPanel: {
            display: 'block',
            backgroundColor: theme.palette.secondary.webpink,
            zIndex: 3,
            marginTop: '64px',
            padding: '45px 167px 37px 166px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 45px 15px 45px'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '20px 20px 15px 20px',
                marginTop: '56px',
            }
        },
        noResultsOverlay: {
            pointerEvents: 'none',
            opacity: '0.5'
        },
        resultsContainer: {
            background: 'white',
            padding: '45px 167px 80px 166px',            
            display: 'block',
            flexGrow: '1',
            boxSizing: 'border-box',
            flexDirection: 'column', 
            '& > div > div:not(:last-child)': {                
                marginBottom: '80px',
                [theme.breakpoints.down('sm')]: {
                    marginBottom: '50px',
                },
                [theme.breakpoints.down('xm')]: {
                    marginBottom: '20px',
                },
            },
            [theme.breakpoints.down('sm')]: {
                padding: '30px 45px 0px 45px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '20px 20px 0px 20px',
            }
        },
        numElectionsLabel: {
            marginBottom: 'initial !important',
        },
        electionContainer: {    
            flexGrow: 1,        
            borderRadius: '4px',            
            border: '4px solid ' + theme.palette.secondary.main,            
        },
        noElectionContainer: {
            borderRadius: '4px',
            color: theme.palette.secondary.charcoal,            
            border: '4px solid ' + theme.palette.secondary.main,
            padding: '36px 45px',
            '& h3': {
                color: theme.palette.secondary.main,
                paddingBottom: '19px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '20px 35px',
                marginBottom: '30px'
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: '20px'
            },
        },
        electionHeading: {
            color: theme.palette.primary.contrastText,
            background: theme.palette.secondary.main,
            padding: '33px 45px 35px 45px',
            '& > p:first-child': {
                fontWeight: 600,
            },
            '& a': {
                fontWeight: 600,
                color: 'inherit',
                cursor: 'pointer',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '23px 35px 25px 35px',
            },
            [theme.breakpoints.down('xm')]: {
                marginBottom: '20px',
                padding: '15px 20px 20px 20px',
            }
        },
        electionInformation: {
            background: theme.palette.primary.contrastText,
            color: theme.palette.secondary.charcoal,
            padding: '36px 45px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 35px',
            },
            '& h3': {
                color: theme.palette.secondary.main,                
            }
        },
        votingTileIcon: {
            height: '48px',
            width: '48px',
            display: 'inline-block',
            verticalAlign: 'top',
            paddingRight: '20px',
            [theme.breakpoints.down('md')]: {
                display: 'block',
                paddingBottom: '17px'
            },
        },
        votingOptionTile: {
            background: theme.palette.primary.contrastText,
            border: '2px solid ' + theme.palette.cloudyBlue,
            borderRadius: '3px',
            boxSizing: 'border-box',
            color: theme.palette.secondary.charcoal,
            width: '100%',
            height: '100%',
            padding: '30px',
            display: 'inline-block',            
            verticalAlign: 'top',
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {
                //width: 'calc(100% / 2 - 70px)',
                padding: '25px',
            },
            [theme.breakpoints.down('xs')]: {
                //width: 'calc(100% - 55px)',
                marginRight: '0px !important',
            },
            '& svg > path': {
                fill: theme.palette.primary.main
            },
            '& h4': {
                color: theme.palette.primary.main,
                fontWeight: 600,
            },
            '& h5': {
                color: theme.palette.primary.main,
                fontWeight: 600,
            },
            '&:hover': {
                color: theme.palette.primary.contrastText + ' !important',
                background: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '& h4': {
                    color: theme.palette.primary.contrastText,
                },
                '& h5': {
                    color: theme.palette.primary.contrastText,
                },
                '& svg > path': {
                    fill: theme.palette.primary.contrastText,
                }
            },
            '&[disabled]': {
                borderColor: theme.palette.gray.gray200,
                color: theme.palette.gray.gray200,
                pointerEvents: 'none',
                '& svg > path': {
                    fill: theme.palette.gray.gray200,
                },
                '& h4': {
                    color: theme.palette.gray.gray200,
                },
                '& h5': {
                    color: theme.palette.gray.gray200,
                },
            },
        },
        attendanceOptionsContainer: {
            marginTop: '45px',
            '& >div:first-child': {
                paddingRight: '15px',
                [theme.breakpoints.down('xs')]: {
                    paddingRight: 'initial',     
                    paddingBottom: '10px'               
                },
            },
            '& >div:last-child': {
                paddingLeft: '15px',                
                [theme.breakpoints.down('xs')]: {
                    paddingLeft: 'initial',
                },
            }
        },
        navArrowIcon: {
            verticalAlign: 'bottom',
            marginLeft: '10px',
            width: '14px',
        },
        votingOptionText: {
            display: 'inline-block',
            width: 'calc(100% - 128px)',
            [theme.breakpoints.down('md')]: {
                width: 'initial',
                paddingLeft: 'initial',
            },
            '& a': {
                fontSize: '18px',
                fontWeight: '600',
                cursor: 'pointer',
            },
        },
        multipleElectionIndicator: {
            width: '48px',
            height: '48px',
            borderRadius: '3px',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            textAlign: 'center',
            display: 'inline-block',
            verticalAlign: 'top',
            marginRight: '32px',
            '& h2':{
                paddingTop: '2px',
            },
            [theme.breakpoints.down('sm')]: {
                width: '46px',
                height: '46px',
                marginRight: '27px',
                '& h2':{
                    paddingTop: '1px',
                },    
            },
            [theme.breakpoints.down('xs')]: {
                width: '40px',
                height: '40px',
                display: 'block',
                '& h2':{
                    paddingTop: '3px',
                },    
            },
        },
        findOutMoreContainer: {
            padding: '35px 45px 46px 45px',
            background: '#e6ebf0',
            '& a': {
                fontWeight: '600',
                cursor: 'pointer',
            },
            '& p': {
                color: theme.palette.secondary.charcoal,
            }
        },
        space: {
            height: '25px',
            [theme.breakpoints.down('sm')]: {
                height: '19px',
            }
        },
        InfoBottom: {
            marginTop: '15px'
        },
        InfoBottomBold: {
            fontWeight: '700'
        },
        divLink: {
            fontWeight: '600',
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#a30134',
        },
        customLink: {            
            textDecoration: 'none',
            color: '#39444f',
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
                color: '#a30134',
            }
        },
    });
}

@inject('electionsStore', 'electoratesStore', 'votingCentresStore', 'mapStore', 'appMainStore')
@observer
class ElectionResults extends React.Component {

    @observable searchAddress = '';

    @action.bound setSearchAddress (address) {
        this.searchAddress = address && address[0];
    }

    componentDidMount() {
        this.searchAddress = this.props.initialAddress; 
    }
    
    @action.bound onVotingCenterSearchAddressChange(places) {
        const { electionsStore } = this.props;
        this.setSearchAddress(places);
        if (places && places.length) {
           electionsStore.getAddressElections(places[0]);
        }
    }

    handleElectionClick = (election, electorate, venueType) => {        
        this.props.electionsStore.setSelectedElection(election.electionId);
        this.props.electionsStore.appMainStore.setShowGenericHeader(true);
        this.props.votingCentresStore.getElectionElectorateVenues(election, electorate, venueType, this.searchAddress);    
    }

    failedStatuses = ["Failed", "Uncontested", "Completed"]; 
    renderElectionDetails(elections) {
        const { classes } = this.props;
        const {isLocationInVictoria} = this.props.appMainStore;
        const {getElectorateProfileName} = this.props.electoratesStore;
        let now = moment.utc().tz('Australia/Melbourne');
        let votingCentreISOSLocalTime = this.searchAddress ? moment(this.calcISOSVotingCentreLocalTime(this.searchAddress.utc_offset_minutes)) : "";
        let electionInfo;
        let earlyVotingStart;
        let earlyVotingFinish;
        let earlyVotingRangeText;
        let failedElectorate;
        let noVotingReason;
        const sortedElections = elections.sort((e1, e2) => {
            if (e1.electionMethod !== e2.electionMethod) {
                return (e1.electionMethod == 'Attendance'? 1 : 2) - (e2.electionMethod == 'Attendance'? 1 : 2)
            } else {
                return moment(e1.electionDate).diff(moment(e2.electionDate));
            }
        });
       
        return sortedElections.map((election, index, allElections) => {
            let targetElectorate;
            let parentElectorateName;
            if (election.electionTypeId == Enums.ElectionType.ByElection) {
                targetElectorate = election.electionElectorates[0];
            } else {
                if (election.electionCategoryId == Enums.ElectionCategory.State) {
                    targetElectorate = election.electionElectorates.find(ee => {return ee.electorateType == 'District' && ee.containsAddress});
                } else {
                    targetElectorate = election.electionElectorates.find(ee => {return ee.electorateType == 'Ward' && ee.containsAddress});
                    if (!targetElectorate) {
                        targetElectorate = election.electionElectorates.find(ee => {return ee.electorateType == 'Council' && ee.containsAddress});
                    }
                }            
            }
            earlyVotingStart = moment.tz(election.earlyVotingStart, 'Australia/Melbourne');            
            earlyVotingFinish = moment.tz(election.earlyVotingFinish, 'Australia/Melbourne');     
            earlyVotingRangeText = Tools.vecFormatDateRange(earlyVotingStart, earlyVotingFinish, ' to ');
            failedElectorate = election.electionElectorates.find(ee => this.failedStatuses.indexOf(ee.electionElectorateStatus) != -1);
            noVotingReason = moment.tz(election.electionDate, 'Australia/Melbourne').add(18, 'hours').isBefore(now)? 'Voting Closed' : failedElectorate? failedElectorate.electionElectorateStatus : '';
            parentElectorateName = targetElectorate && targetElectorate.parentElectorate ? this.getElectorateName(targetElectorate.parentElectorate.electorateName,targetElectorate.parentElectorate.electorateType): null;

            if (noVotingReason) {
                switch (noVotingReason) {
                    case "Completed":
                    case 'Failed': 
                    electionInfo = (<div className={classes.electionInformation}>
                            <Typography variant='h3'>Failed election</Typography>
                            <div className={classes.space}></div>
                            <Typography variant='body1'>You are not required to vote in this election. At the close of nominations, there were either no candidates or fewer candidates than vacancies in this election. A by-election will be held at a future date. <Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/elections/'>Find out more about this election.</Link>
                            </Typography>
                        </div>)
                    break;
                    case 'Uncontested':
                            electionInfo = (<div className={classes.electionInformation}>
                                <Typography variant='h3'>Uncontested election</Typography>
                                <div className={classes.space}></div>
                                <Typography variant='body1'>You are not required to vote in this election. At the close of nominations, the number of candidates was the same as the number of vacancies in this election. <Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/elections/'>Find out more about this election.</Link></Typography>
                            </div>)
                    break;
                    case 'Voting Closed':
                            electionInfo = (<div className={classes.electionInformation}>
                                <Typography variant='h3'>Voting for this election has now closed</Typography>
                            </div>);
                    break;
                }
            } else {
                if (isLocationInVictoria) {
                    switch (election.electionMethod) {
                        case 'Attendance':
                            electionInfo = (<div className={classes.electionInformation}>
                                <Typography variant='h3'>Vote at a voting centre</Typography>
                                <Typography variant='body1'>Select an option below to see matching voting centres.</Typography>
                                <Grid container className={classes.attendanceOptionsContainer}>
                                    <Box component={Grid} item sm={6} xs={12} display={{ xs: (earlyVotingFinish.isBefore(now)? 'none': 'flex'), sm: 'flex' }} > 
                                        <div className={classes.votingOptionTile} disabled={earlyVotingFinish.isBefore(now)} onClick = {() => this.handleElectionClick(election, targetElectorate, Enums.VotingCentreType.EarlyVotingCentre)}>
                                            <EarlyVotingIcon className={classes.votingTileIcon} />
                                            <div className={classes.votingOptionText}>
                                                <Typography variant='h4'>Vote early</Typography>
                                                <Typography variant='body1'>Early voting centres are open from {earlyVotingRangeText}.</Typography>
                                                <br />
                                                {election.electionTypeId == Enums.ElectionType.General && election.electionCategoryId == Enums.ElectionCategory.State &&
                                                    <React.Fragment>
                                                        <Typography variant='body1'>If you vote outside your enrolled district, it may take longer.</Typography>   
                                                        <br />
                                                    </React.Fragment>}
                                                <Typography variant='h5'>View early voting centre locations <NavForwardArrowIcon className={classes.navArrowIcon} /></Typography>
                                            </div>
                                        </div>
                                    </Box>
                                    <Grid item sm={6} xs={12}>
                                        <div className={classes.votingOptionTile} onClick = {() => this.handleElectionClick(election, targetElectorate, Enums.VotingCentreType.ElectionDayVotingCentre)}>
                                            <ElectionDayVotingIcon className={classes.votingTileIcon} />
                                            <div className={classes.votingOptionText}>
                                                <Typography variant='h4'>Vote on election day</Typography>
                                                <Typography variant='body1'>Election day voting centres are open on {moment.tz(election.electionDate, 'Australia/Melbourne').format('dddd D MMMM')} from 8 am to 6 pm.</Typography>
                                                <br />
                                                <Typography variant='h5'>View election day voting centre locations <NavForwardArrowIcon className={classes.navArrowIcon} /></Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>);
                            break;
                        case 'Postal':
                            electionInfo = (<div className={classes.electionInformation} >
                                <div className={classes.votingOptionText}>
                                    <PostalVotingIcon className={classes.votingTileIcon} />
                                    <Typography variant='h3' display='inline'>Postal election</Typography>                            
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Voting in this election will be by post.</span> <span>We will post ballot packs to enrolled voters from </span>{moment.tz(election.postalVotingStart, 'Australia/Melbourne').format('dddd D MMMM')}.</Typography>
                                    <Typography variant='body1'>Follow the instructions on the ballot pack to complete your vote. Make sure you post it back before <span style={{fontWeight: 'bold'}}>{moment.tz(election.postalVotingFinish, 'Australia/Melbourne').format('h.mm a')}</span> on <span style={{fontWeight: 'bold'}}>{moment.tz(election.postalVotingFinish, 'Australia/Melbourne').format('dddd D MMMM')}</span>.<br/>
                                    <Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/elections/'>More information about this election.</Link></Typography>
                                    <br/>  
                                    {/* Election office */}
                                    <ElectionOfficeIcon className={classes.votingTileIcon} />
                                    <Typography variant='h3' display='inline'>Election office</Typography>                                                                       
                                    <Typography variant='body1'>You can go to the election office to nominate as a candidate, or if you need help with voting.</Typography>
                                    <Typography variant='body1'><Link underline='always' target='_blank' onClick={() => this.handleElectionClick(election, targetElectorate, Enums.VotingCentreType.ElectionOffice)}>Find election office details.</Link></Typography>
                                </div>
                            </div>);
                            break
                    }
                } else {
                    electionInfo = (<div className={classes.electionInformation}>
                        <Typography variant='h3'>Vote at a voting centre</Typography>
                        <Typography variant='body1'>Select an option below to see matching voting centres.</Typography>
                        <Grid container className={classes.attendanceOptionsContainer}>
                            <Grid item sm={6} xs={12} >
                                <div className={classes.votingOptionTile} style={{ marginRight: '27px' }} disabled={earlyVotingFinish.isBefore(votingCentreISOSLocalTime)} onClick={() => this.handleElectionClick(election, null, Enums.VotingCentreType.ISOSVotingCentre)}>
                                    <EarlyVotingIcon className={classes.votingTileIcon} />
                                    <div className={classes.votingOptionText}>
                                        <Typography variant='h4'>Vote early</Typography>
                                        <Typography variant='body1'>Early voting centres are open from {earlyVotingRangeText}. Check times.</Typography>
                                        <br />
                                        <Typography variant='h5'>View early voting centre locations <NavForwardArrowIcon className={classes.navArrowIcon} /></Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div className={classes.votingOptionTile} disabled>
                                    <ElectionDayVotingIcon className={classes.votingTileIcon} />
                                    <div className={classes.votingOptionText}>
                                        <Typography variant='h4'>Vote on election day</Typography>
                                        <Typography variant='body1'>Election day voting centres are open on {moment.tz(election.electionDate, 'Australia/Melbourne').format('dddd D MMMM')} from 8 am to 6 pm.</Typography>
                                        <br />
                                        <Typography variant='h5'>View election day voting centre locations <NavForwardArrowIcon className={classes.navArrowIcon} /></Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>);
                }   
            }
            let votingInElectorateText = '';
            if (isLocationInVictoria && election.electionTypeId == Enums.ElectionType.General){
                let labelElectorate;
                switch (election.electionCategoryId){
                    case Enums.ElectionCategory.Municipal:
                        labelElectorate = election.electionElectorates.find(ee => {return ee.electorateType == 'Ward' && ee.containsAddress});
                        if (labelElectorate) {
                            votingInElectorateText = labelElectorate.parentElectorate.electorateName;                    
                            if (labelElectorate.electorateName != 'Unsubdivided') votingInElectorateText += ', ' + labelElectorate.electorateName + ' Ward';
                        } else {
                            labelElectorate = election.electionElectorates.find(ee => {return ee.electorateType == 'Council' && ee.containsAddress});                            
                            votingInElectorateText = labelElectorate.electorateName;                    
                        }
                    break;
                    case Enums.ElectionCategory.State:
                            labelElectorate = election.electionElectorates.find(ee => {return ee.electorateType == 'District' && ee.containsAddress});
                            votingInElectorateText = labelElectorate.electorateName + ' ' + labelElectorate.electorateType + ', ' + labelElectorate.parentElectorate.electorateName + ' ' + labelElectorate.parentElectorate.electorateType;
                            break;
                }
            }
            return (
                <div key={'Election' + index} className={classes.electionContainer}>
                    <div className={classes.electionHeading}>
                        {allElections.length > 1? <div className={classes.multipleElectionIndicator}><Typography variant='h2' color='secondary'>{index + 1}</Typography></div> : null }
                        <div style={{display: 'inline-block'}}>
                            <Typography variant='h2'>{election.electionName}</Typography>
                            <Typography variant='body1' style={{fontWeight: 600}}>{moment.tz(election.electionDate, 'Australia/Melbourne').format('dddd D MMMM YYYY')}</Typography>                            
                            { votingInElectorateText.length?
                            <Typography variant='body1'>{noVotingReason.length == 0? 'This address is in': 'Your electorate is'}: {election.electionCategoryId == Enums.ElectionCategory.Municipal? this.getElectorateName(targetElectorate.electorateName,targetElectorate.electorateType): 
                                <Link underline='always' target='_blank' href={getElectorateProfileName(targetElectorate)}>{this.getElectorateName(targetElectorate.electorateName,targetElectorate.electorateType)}</Link>}
                                {parentElectorateName && parentElectorateName.length > 0 &&  
                                <span>,&nbsp;<Link underline='always' target='_blank' href={getElectorateProfileName(targetElectorate.parentElectorate)}>{parentElectorateName}</Link></span>}
                            </Typography> : null}
                        </div>
                    </div>
                    {electionInfo}
                    {(noVotingReason == '' || (election.electionTypeId == Enums.ElectionType.General && election.electionCategoryId == Enums.ElectionCategory.Municipal)) &&
                    <div className={classes.findOutMoreContainer}>
                        {noVotingReason == '' && <Typography variant='body1'>
                            <div><span className={classes.InfoBottomBold}>Away from home?</span> {election.electionTypeId == Enums.ElectionType.ByElection ? <Link underline='always' target='_blank' href='https://vec.vic.gov.au/away'>Find out about other ways you can vote</Link> : <Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/voting'>Find out about other ways you can vote</Link>}.</div>
                            {election.electionCategoryId != Enums.ElectionCategory.Municipal && election.electionTypeId == Enums.ElectionType.General && <div className={classes.InfoBottom}><span className={classes.InfoBottomBold}>Interstate or overseas? </span><span> Search for your </span><span className={classes.divLink} onClick={() => this.handleElectionClick(election, targetElectorate, Enums.VotingCentreType.EarlyVotingCentre)}>nearest early voting centre/postal ballot drop-off location</span><span> or </span><Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/postalvote'>apply to vote by post</Link>.</div>}
                            {election.electionTypeId == Enums.ElectionType.ByElection ?
                                <div className={classes.InfoBottom}><span className={classes.InfoBottomBold}>Election office. </span><span>Independent candidates can submit a nomination until 12 noon on {moment.tz(election.nominationsFinish, 'Australia/Melbourne').format('dddd D MMMM')}. </span><span className={classes.divLink} onClick={() => this.handleElectionClick(election, targetElectorate, Enums.VotingCentreType.ElectionOffice)}>View election office</span>.</div> :
                                <div className={classes.InfoBottom}><span className={classes.InfoBottomBold}>Election offices. </span><span>Independent candidates can submit a nomination until 12 noon on {moment.tz(election.nominationsFinish, 'Australia/Melbourne').format('dddd D MMMM')}. </span><span className={classes.divLink} onClick={() => this.handleElectionClick(election, targetElectorate, Enums.VotingCentreType.ElectionOffice)}>View election offices</span>.</div> }
                            {election.electionCategoryId != Enums.ElectionCategory.Municipal && <div className={classes.InfoBottom}><span>Find out more about </span><Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/voting/how-voting-works/what-happens-at-a-voting-centre#accessibility'>accessible voting</Link><span> and </span><Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/voting/how-voting-works/what-happens-at-a-voting-centre'>what happens at a voting centre</Link>.</div>}
                        </Typography>}
                        {election.electionTypeId == Enums.ElectionType.General &&
                            election.electionCategoryId == Enums.ElectionCategory.Municipal &&
                            <Typography variant='body1'><div className={classes.InfoBottom}><span style={{ fontWeight: 'bold'}}>Own or occupy property in another council?</span> You may also be eligible to vote for that address.&nbsp;<Link underline='always' target='_blank' href='https://www.vec.vic.gov.au/enrolment/enrol-to-vote/enrolling-for-local-council-elections'>Find out more about who can enrol in council elections.</Link></div></Typography>
                        }
                    </div>}
                </div>
            );
        });
    }

    getElectorateName(electorateName,electorateType) {             
        let formattedElectorateName  = ""; 
        try{
            // remove duplicated 'Council' word if any
            if (electorateName.toLowerCase().indexOf("council") > -1 && electorateType.toLowerCase().indexOf("council") > -1) {
                formattedElectorateName = electorateName + " " + electorateType.replace('council','').replace('Council','');
            }
            else {
                formattedElectorateName = electorateName + " " + electorateType; 
            }                
        }
        catch(error) {
            console.log('getElectorateName error: '+ error)
        }        
        return formattedElectorateName;
    }

    calcISOSVotingCentreLocalTime(offset) {
        let clientDate = new Date();
        let clientDateUtc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
        let localTime = new Date(clientDateUtc + (60000*offset));
        return localTime;
    }

    render() {
        const { mapStore, classes } = this.props;
        const { elections, searchInProgress,selectedElection } = this.props.electionsStore;
        const {isLocationInVictoria} = this.props.appMainStore;
        const {isTimeOut} = this.props.electionsStore;
        return (<React.Fragment>
            <div className={classes.searchPanel}>
                <AddressSearchBox
                    placeholder='Enter your address'
                    defaultAddress={this.searchAddress}
                    google={mapStore.googleSDK}                    
                    map={mapStore.map}
                    onPlacesChanged={this.onVotingCenterSearchAddressChange}
                    isLandingInput={true} />
            </div>
            {!searchInProgress &&
                <div className={classes.resultsContainer}>
                    <div className={!this.searchAddress ? classes.noResultsOverlay :'' }>                        
                        {elections.length > 1 && !isTimeOut && <div className={classes.numElectionsLabel}><Typography display='inline'>There are </Typography><Typography display='inline' color='secondary' style={{fontWeight: 'bold'}}>{elections.length.toString()+ " elections"} </Typography><Typography display='inline'> in this area: </Typography><p/></div>}
                        {/* changed for ByElection */}
                        {/* {!isLocationInVictoria && !isTimeOut && <div className={classes.noElectionContainer}><Typography variant='h3'>This address is outside Victoria</Typography>To find your nearest interstate or overseas voting option, select &apos;Vote early&apos; from the options below. You can also <Link href='https://www.vec.vic.gov.au/postalvote' underline='always' target='_blank'>apply to vote by post</Link>.</div>} */}
                        {!isLocationInVictoria && !isTimeOut && <div className={classes.noElectionContainer}>
                            <Typography variant='h3'>This address is outside Victoria</Typography> 
                            <Typography variant='body1'>The VEC holds Victorian state and local council elections.</Typography>
                            <Typography variant='body1'>For information about federal elections, <Link href='https://www.aec.gov.au/' underline='always' target='_blank'>visit the Australian Electoral Commission website</Link>.</Typography>                            
                            <Typography variant='body1'>For information about other state elections, you can visit:</Typography>
                            <Typography variant='body1'><Link href='https://www.elections.act.gov.au/' underline='always' target='_blank'>Elections ACT</Link></Typography> 
                            <Typography variant='body1'><Link href='https://www.ecq.qld.gov.au/' underline='always' target='_blank'>Electoral Commission Queensland</Link></Typography>
                            <Typography variant='body1'><Link href='https://www.ecsa.sa.gov.au/' underline='always' target='_blank'>Electoral Commission South Australia</Link></Typography>
                            <Typography variant='body1'><Link href='https://ntec.nt.gov.au/' underline='always' target='_blank'>Northern Territory Electoral Commission</Link></Typography>
                            <Typography variant='body1'><Link href='https://elections.nsw.gov.au/' underline='always' target='_blank'>NSW Electoral Commission</Link></Typography>
                            <Typography variant='body1'><Link href='https://www.tec.tas.gov.au/' underline='always' target='_blank'>Tasmanian Electoral Commission</Link></Typography>
                            <Typography variant='body1'><Link href='https://www.elections.wa.gov.au/' underline='always' target='_blank'>Western Australian Electoral Commission</Link></Typography>
                            </div>}
                        
                        {this.renderElectionDetails(elections)}
                        {elections.length == 0 && isLocationInVictoria && !isTimeOut && <div className={classes.noElectionContainer}>
                            <Typography variant='h3'>There are no active Victorian elections for this address</Typography>
                            <Typography variant='body1'>The VEC holds Victorian state and local council elections.</Typography>
                            <Typography variant='body1'>For information about federal elections, <Link href='https://www.aec.gov.au/' underline='always' target='_blank'>visit the Australian Electoral Commission website</Link>.</Typography>   
                            </div>}
                        {isTimeOut && <div className={classes.noElectionContainer}><Typography variant='h3'>Session timeout</Typography>Your session has timed out. Please search again.</div>}
                    </div>
                </div>
            }
            <BrowseFooter/>
        </React.Fragment>);
    }
    static propTypes = {
        electionsStore: PropTypes.object,
        electoratesStore: PropTypes.object,
        votingCentresStore: PropTypes.object,
        mapStore: PropTypes.object,
        appMainStore: PropTypes.object,
        initialAddress: PropTypes.object,
        classes: PropTypes.object,   

    }
}

export default withStyles(styles)(ElectionResults);